
.p-cookiepolicy {
  padding-top: 90px;
  @include sp{
    padding-top: 120/750*100vw;
  }
  &__inner{
    padding: 0 103px 80px;
    @include sp{
      padding: 0 40/750*100vw 80/750*100vw;
    }
  }
  &__breadcrumb{
    display: flex;
    padding-top: 30px;
    margin-bottom: 50px;
    @include sp{
      padding-top: 6.66667vw;
      margin-bottom: 0;
    }
    span{
      font-size: 14px;
      margin: 0 5px;
      @include sp{
        @include vw(24);
        margin: 0 10/750*100vw;
      }
    }
    a{
      font-size: 14px;
      text-decoration: underline;
      @include sp{
        @include vw(24);
      }
    }
    p{
      font-size: 14px;
      @include sp{
        @include vw(24);
      }
    }
  }
  &__ttl{
    font-size: 34px;
    font-weight: normal;
    margin-bottom: 70px;
    @include sp{
      @include vw(44);
      padding-top: 50px;
      margin-bottom: 70/750*100vw;
    }
  }
  &__txt{
    margin-bottom: 80px;
    @include sp{
      @include vw(28);
      margin-bottom: 80/750*100vw;
    }
  }
  &__ttl2{
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 25px;
    @include sp{
      @include vw(40);
      margin-bottom: 25/750*100vw;
    }
  }
}
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table tr th:last-child, #ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy table tr td:last-child{
  width: 45%;
  @media only screen and (max-width: 530px){
    width: auto;
  }
}
.ot-life-span{
  white-space: nowrap;
}
