@charset "utf-8";

.p-system {
    background         : url(/assets/img/system/system_bg.jpg) no-repeat;
    background-position: left;
    background-size    : cover;
    height             : 750px;
    position           : relative;
    z-index: 2;
    @include rsp {
        height             : 750/1366*100vw;
    }
    @include sp{
        background         : url(/assets/img/system/system_bg_sp.jpg) no-repeat;
        background-position: center;
        background-size: cover;
        height             : 874/750*100vw;
    }

    &__inner {
        padding: 130px 103px 0;
        @include rsp {
            padding: 130/1366*100vw 103/1366*100vw 0;
        }
        @include sp{
            padding: 260/750*100vw 40/750*100vw 0;
        }
    }

    &__ttl {
        color: #fff;
        @include rsp {
            font-size: 16/1366*100vw;
        }
        @include sp{
            @include vw(28);
        }
    }

    &__ttl2 {
        color: #fff;
        @include sp{
            margin-bottom: 15/750*100vw;
        }
    }

    &__lead {
        color: #fff;

        @include rsp {
            font-size: 16/1366*100vw;
        }
        @include sp{
            @include vw(28);
        }
        @include en{
            font-family: Montserrat,"Noto Sans JP";
            padding-top: 20px;
            @include sp{
                padding-top: 10/750*100vw;
            }
        }
    }

    &__btn01 {
        position: absolute;
        top     : 38%;
        left    : 480px;
        cursor  : pointer;

        @include rsp {
            left: 480/1366*100vw;
        }
        @include sp{
            top     : 62%;
            left    : 300/750*100vw;
            width: 80/750*100vw;
        }

        &--after {
            position : absolute;
            transform: translate(-50%, -50%);
            top      : 35px;
            width    : 338px;
            left     : 35px;
            display  : none;
            @include rsp {
                top      : 35/1366*100vw;
                width    : 338/1366*100vw;
                left     : 35/1366*100vw;
            }
            @include sp{
                top      : 35/750*100vw;
                left     : 35/750*100vw;
                width    : 308/750*100vw;
            }
        }
    }

    &__btn02 {
        position: absolute;
        top     : 43%;
        left    : 950px;
        cursor  : pointer;

        @include rsp {
            left: 950/1366*100vw;
        }
        @include sp{
            top     : 65%;
            left    : 570/750*100vw;
            width: 80/750*100vw;
        }

        &--after {
            position : absolute;
            transform: translate(-50%, -50%);
            top      : 35px;
            width    : 338px;
            left     : 35px;
            display  : none;
            @include rsp {
                top      : 35/1366*100vw;
                width    : 338/1366*100vw;
                left     : 35/1366*100vw;
            }
            @include sp{
                top      : 35/750*100vw;
                left     : 35/750*100vw;
                width    : 308/750*100vw;
            }
        }
    }

    /*&__btn03 {
        position: absolute;
        top     : 67%;
        left    : 300px;
        cursor  : pointer;

        @include rsp {
            left: 300/1366*100vw;
        }
        @include sp{
            top     : 80%;
            left    : 100/750*100vw;
            width: 80/750*100vw;
        }

        &--after {
            position : absolute;
            transform: translate(-50%, -50%);
            top      : 35px;
            width    : 338px;
            left     : 35px;
            display  : none;
            @include rsp {
                top      : 35/1366*100vw;
                width    : 338/1366*100vw;
                left     : 35/1366*100vw;
            }
            @include sp{
                top      : 35/750*100vw;
                left     : 35/750*100vw;
                width    : 308/750*100vw;
            }

        }
    }*/
    &__btnImg{
        width: 80px;
        @include rsp{
            width: 80/1366*100vw;
        }
    }
    &__btn--after img{
        width: 338px;
        @include rsp{
            width:338/1366*100vw;
        }
    }
    /*破門*/
    &__btn::before, &__btn-btn::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        border: 1px solid #2189CF;
        border-radius: 50%;
        box-sizing: border-box;
        pointer-events: none;
        animation: pulsate 2s linear infinite;
      }
      
      &__btn::after {
        animation-delay: 1s;
      }
      
      @keyframes pulsate {
        0% {
          transform: scale(1);
          opacity: 1;
        }
      
        100% {
          transform: scale(1.5);
          opacity: 0;
        }
      }



}
.btnripple2{
    /*波紋の基点とするためrelativeを指定*/
  position: relative;
    /*波紋の形状*/
  display:inline-block;
  background:#333;
  width:70px;
  height:70px;
  border-radius: 50%;
  color:#ccc;
    outline: none;
    /*アニメーションの設定*/
    transition: all .3s;
}

/*hoverした際の背景色の設定*/
.btnripple2:hover{
  background:#666;  
}

/*波形を2つ設定*/
.btnripple2::after,
.btnripple2::before {
    content: '';
    /*絶対配置で波形の位置を決める*/
    position: absolute;
    left: -25%;
    top: -25%;
    /*波形の形状*/
    border: 1px solid #333;
    width: 150%;
    height: 150%;
    border-radius: 50%;
    /*はじめは不透明*/
    opacity: 1;
    /*ループするアニメーションの設定*/
    animation:1s circleanime linear infinite;
}

/*波形の2つ目は0.5秒遅らせてアニメーション*/
.btnripple2::before {
    animation-delay:.5s; 
}

/*波形のアニメーション*/
@keyframes circleanime{
  0%{
    transform: scale(0.68);
  }
  100%{
    transform: scale(1.2);
    opacity: 0;
  }
}

/*中央矢印*/
.btnripple2 span::after {
    content: '';
    /*絶対配置で矢印の位置を決める*/
    position: absolute;
    top: 38%;
    left: 45%;
    /*矢印の形状*/
    border: 14px solid transparent;
    border-top-width: 10px;
    border-bottom-width: 10px;
    border-left-color: #fff;
}