@charset "utf-8";

.l-header {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    padding-left   : 40px;
    font-family    : "Montserrat", "Noto Sans JP";
    font-weight    : 600;
    position: fixed;
    z-index: 999;
    width: 100%;
    background-color: #EDEDED;
    min-width: 1300px;
    overflow: hidden;

    @include sp {
        padding-left: 0;
        height: 120/750*100vw;
        width: 100%;
    }
    /*@include rsp{
        padding-left: 40/1366*100vw;
    }*/

    &__logo {
        @include sp {
            width      : 222/750*100vw;
            margin-left: 27/750*100vw;
        }
    }

    &__menu {
        display: flex;
        @include sp{
            display: block;
            padding: 0 80/750*100vw;
            padding-top: 100/750*100vw;
        }

        li{
            @include sp{
                padding-top: 45/750*100vw;
            }
        }

        a {
            padding: 0 20px;
            position: relative;
            @include sp{
                padding: 0;
                font-size: 33/750*100vw;
            }
            /*@include rsp{
                padding: 0 20/1366*100vw;
                font-size: 16/1366*100vw;
            }*/
            /*&:hover{
                &::after{
                    content: "";
                    position: absolute;
                    height: 3px;
                    width: 65%;
                    bottom: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    background     : rgb(73, 201, 162);
                    background     : linear-gradient(90deg, rgba(73, 201, 162, 1) 0%, rgba(52, 167, 187, 1) 50%, rgba(31, 134, 210, 1) 100%);
                    @include sp{
                        display: none;
                    }
                    }
            }*/
        }
    }

    &__lang {
        display: flex;
        padding: 0 20px;

        @include sp {
            padding: 0 80/750*100vw;
        }
        /*@include rsp{
            padding: 0 20/1366*100vw;
        }*/

        li {
            padding    : 0 0 0 5px;
            line-height: 1;

            @include sp {
                padding: 0;
                width: 60/750*100vw;
                height: 100%;
                margin-top: 45/750*100vw;
            }
            /*@include rsp{
                padding: 0 0 0 5/1366*100vw;
            }*/

            a {
                font-size: 14px;
                font-weight   : 500;
                color         : $main_blue;
                letter-spacing: 0.05em;
                @include sp{
                    width: 100%;
                    @include vw(27);
                }
            }

            &.is-active {
                a {
                    color: #BDBDBD;
                }
            }

            &:first-child {
                position: relative;
                &::after {
                    content: "/";
                    color  : #BDBDBD;
                    @include sp{
                        @include vw(27);
                        position: absolute;
                        top: 0;
                        left: 42/750*100vw;
                    }
                }
            }
        }
    }

    &__wrap {
        display    : flex;
        align-items: center;
        @include sp{
            display: block;
            height: 100%;
        }
    }
}


.l-headerBtn {
    align-items    : center;
    background     : rgb(73, 201, 162);
    background     : linear-gradient(90deg, rgba(73, 201, 162, 1) 0%, rgba(52, 167, 187, 1) 50%, rgba(31, 134, 210, 1) 100%);
    justify-content: center;
    display        : flex;
    height         : 90px;
    width          : 103px;

    @include sp {
        height: 120/750 * 100vw;
        width : 100%;
        position: absolute;
        bottom: 0
    }
    /*@include rsp{
        height: 90/1366 * 100vw;
        width          : 103/1366 * 100vw;
    }*/

    &__text {
        color         : #fff;

        @include sp {
            @include vw(33);
            margin-top: 0;
        }
        /*@include rsp {
            font-size: 16/1366*100vw;
        }*/

    }
}

@include sp {
    #g-nav {
        /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
        position  : fixed;
        flex-wrap: wrap;
        z-index   : 999;
        /*ナビのスタート位置と形状*/
        top       : 0;
        right     : -120%;
        width     : 72%;
        height    : 100vh;
        /*ナビの高さ*/
        background: #EDEDED;
        /*動き*/
        transition: all 0.6s;
    }

    /*アクティブクラスがついたら位置を0に*/
    #g-nav.panelactive {
        right: 0;
    }

    /*ナビゲーションの縦スクロール*/
    #g-nav.panelactive #g-nav-list {
        /*ナビの数が増えた場合縦スクロール*/
        position                  : fixed;
        z-index                   : 999;
        width                     : 100%;
        height                    : 100vh;
        /*表示する高さ*/
        overflow                  : auto;
        -webkit-overflow-scrolling: touch;
    }



    /*リストのレイアウト設定*/

    #g-nav li a {
        display        : block;

        font-weight    : bold;
        text-align: left;
    }


    .openbtn1 {
        position: fixed;
        z-index : 9999;
        /*ボタンを最前面に*/
        top     : 40/750*100vw;
        right   : 40/750*100vw;
        cursor  : pointer;
        width   : 50/750*100vw;
        height  : 40/750*100vw;
    }

    /*×に変化*/
    .openbtn1 span {
        display         : inline-block;
        transition      : all .4s;
        position        : absolute;
        left            : 0;
        height          : 2/750*100vw;
        border-radius   : 2/750*100vw;
        background-color: #000;
        width           : 100%;
    }

    .openbtn1 span:nth-of-type(1) {
        top: 0;
    }

    .openbtn1 span:nth-of-type(2) {
        top: 50%;
    }

    .openbtn1 span:nth-of-type(3) {
        top: 100%;
    }

    .openbtn1.active span:nth-of-type(1) {
        top      : 50%;
        left     : 0;
        transform: translateY(0) rotate(-45deg);
        width    : 100%;
    }

    .openbtn1.active span:nth-of-type(2) {
        opacity: 0;
    }

    .openbtn1.active span:nth-of-type(3) {
        top      : 50%;
        left     : 0;
        transform: translateY(0) rotate(45deg);
        width    : 100%;
    }
    .l-header__cover.active{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9997;
        background: rgba(0,0,0,.8);
        display: block;
        z-index: 1;
    }
}
.l-header__cover{
    display: none;
}
.current{
    &::after{
    content: "";
    position: absolute;
    height: 3px;
    width: 65%;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    background     : rgb(73, 201, 162);
    background     : linear-gradient(90deg, rgba(73, 201, 162, 1) 0%, rgba(52, 167, 187, 1) 50%, rgba(31, 134, 210, 1) 100%);
    @include sp{
        display: none;
    }
    }
}
