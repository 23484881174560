@charset "utf-8";

.p-concept {
    height: 1140px;
    @include sp{
        height: 2320/750*100vw;
    }
    @include en{
        height: 1205px;
        @include sp{
            height: 2570/750*100vw;
        }
    }
    &__inner{
        padding: 130px 103px 0;
        width: 100%;
        position: relative;
        height: 1060px;
        background: #ededed url(/assets/img/concept/concept_bg.jpg) no-repeat;
        background-position: right bottom;
        
        @include rsp {
            padding: 130px 103/1366*100vw 0;
        }
        @include sp {
            background: #ededed url(/assets/img/concept/concept_bg_sp.jpg) no-repeat;
            padding: 130/750*100vw 40/750*100vw 0;
            height: 89%;
            background-position: right top 24%;
            background-size: 28% auto;
        }
        @include en{
            background: #ededed url(/assets/img/concept/en/concept_bg.jpg) no-repeat;
            background-position: right bottom;
            font-family  : "Montserrat", "Noto Sans JP";
            height: 90%;
            @include sp {
                background: #ededed url(/assets/img/concept/concept_bg_sp.jpg) no-repeat;
                padding: 130/750*100vw 40/750*100vw 0;
                height: 92%;
                background-position: right top 15%;
                background-size: 28% auto;
            }
        }
    }
    &__ttl2{
        position: relative;
        z-index: 2;
    }
    &__img{
        position: absolute;
        left: 660px;
        top: 200px;
        @include rsp{
            left: 660/1366*100vw;
            top: 200px;
        }
        @include sp{
            position: static;
            margin-bottom: 80/750*100vw;
        }
        @include en{
            left: 685px;
            top: 140px;
            @include rsp{
                left: 685/1366*100vw;
                top: 140px;
            }
        }
        img{
            width: 548px;
            position: relative;
            z-index: 2;
            @include rsp {
                width: 548/1366*100vw;
                max-width: 750px;
            }
            @include sp{
                width: 670/750*100vw;
            }

        }
    }
    &__txt{
        width: 550px;
        position: relative;
        z-index: 2;
        @include sp{
            width: 100%;
        }
        @include en{
            width: 575px;
            @include sp{
                width: 100%;
            }
        }
        h3{
            margin-left: 95px;
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 40px;
            @include sp{
                margin-left: 0;
                @include vw(36);
                margin-bottom: 40/750*100vw;
            }
            @include en{
                line-height: 1.4;
                font-weight: 600;
                @include sp{
                    line-height: 1.5;
                    width: 100vw;
                }
            }
        }
        &Lead{
            margin-left: 95px;
            margin-bottom: 70px;
            line-height: 2.125em;
            @include sp{
                margin-left: 0;
                @include vw(28);
                margin-bottom: 80/750*100vw;
                padding-bottom: 10/750/100vw;
                line-height: 1.71em;
            }
            @include en{
                line-height: 1.87;
                @include sp{
                    line-height: 1.71;
                }
            }
            span{
                font-size: 10px;
                @include sp{
                    @include vw(15);
                }
            }  
            sup{
                @include en{
                    font-size: 10px;
                    vertical-align: 4px ;
                    @include sp{
                        vertical-align: 6/750*100vw ;
                        @include vw(15);
                    }
                }
            }    
        }
    }
    &__movie{
        margin-left: 95px;
        width: 570px;
        height: 321px;
        position: relative;
        z-index: 2;
        @include sp{
            margin-left: 0;
            height: 290/750*100vw;
            width: 100%;
        }
        &Ttl{
            font-size: 20px;
            margin-bottom: 15px;
            @include sp{
                @include vw(28);
                
                
            }
        }
        iframe{
            width: 570px;
            height: 321px;
            position: absolute;
            z-index:4;
            @include sp{
                bottom: -150/750*100vw;
                width: 670/750*100vw;
                height: 377/750*100vw;
                left: 0/750*100vw;
            }
        }
    }
    &__movieThumb{
        position: absolute;
        cursor: pointer;
        z-index: 6;
        
        @include sp{
            bottom: -150/750*100vw;
            width: 670/750*100vw;
            height: 377/750*100vw;
            left: 0/750*100vw;
        }
    }
}