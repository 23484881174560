.m-ttl {
  position    : relative;
  padding-left: 20px;
  margin-bottom: 20px;

  /*@include rsp {
    font-size: 16/1366*100vw;
    padding-left: 20/1366*100vw;
    margin-bottom: 20/1366*100vw;
  }*/
  @include sp{
    padding-left: 38/750*100vw;
    margin-bottom: 20/750*100vw;
    @include vw(28);
  }


  &::before {
    content            : "";
    background         : url(/assets/img/common/m-ttl.svg) no-repeat;
    background-position: center;
    background-size    : cover;
    position           : absolute;
    top                : 23%;
    width              : 13px;
    height             : 15px;
    left               : 0;

    /*@include rsp {
      width : 13/1366*100vw;
      height: 15/1366*100vw;
    }*/
    @include sp{
      width : 22.76/750*100vw;
      height: 26.28/750*100vw;
    }

  }


}

.m-ttl2 {
  font-size    : 69px;
  font-family  : "Montserrat", "Noto Sans JP";
  font-weight  : 600;
  margin-bottom: 50px;
  line-height  : 1.2em;

  /*@include rsp {
    font-size: 69/1366*100vw;
    margin-bottom: 50/1366*100vw;
  }*/
  @include sp{
    @include vw(80);
    margin-bottom: 50/750*100vw;
  }
}

.m-ttl2Mb0 {
  font-size    : 69px;
  font-family  : "Montserrat", "Noto Sans JP";
  font-weight  : 600;
  margin-bottom: 0;
  line-height  : 1.2em;

  /*@include rsp {
    font-size: 69/1366*100vw;
  }*/
  @include sp{
    @include vw(80);
  }
}