@charset "utf-8";

.p-about{
    position: relative;
    z-index: 2;

    &__img{
        img{
            width: 100%;

        }
    }
    &__txt{
        text-align: center;
        color: #fff;
        line-height: 1.88;
        font-size: 34px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        width: 100%;
        /*@include rsp {
            font-size: 34/1366*100vw;
        }*/
        @include sp{
            @include vw(36);
            line-height: 1.66;
            
        }
        @include en{
            font-family  : "Montserrat", "Noto Sans JP";
          
        }
    }

}