@charset "utf-8";

.p-contact{
    background: rgb(73,201,162);
    background: linear-gradient(90deg, rgba(73,201,162,1) 0%, rgba(52,167,187,1) 50%, rgba(31,134,210,1) 100%);
    height: 400px;
    position: relative;
    z-index: 2;
    @include sp{
        height: 400/750*100vw;
    }

    &__inner{
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 400px;
        @include sp{
            height: 100%;
        }
    }
    &__ttl{
        color: #fff;
        @include sp{
            @include vw(28);
        }
    }
    &__ttl2{
        color: #fff;
    }
}