@charset "utf-8";
.l-footer {
    background: #005bac;
    min-width: 1300px;
    overflow: hidden;
    @include sp{
        min-width: 100%;
    }

    &__wrap {
        width: 1180px;
        margin: 0 auto;
        padding: 26px 20px;

        @include sp {
            padding: 40/750 * 100vw 0 45/750 * 100vw;
            width: 100%;
        }
        /*@include rsp{
            width: 1180/1366*100vw;
            padding: 26/1366*100vw 20/1366*100vw;
        }*/
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include sp {
            display: block;
        }
    }

    &__logo {
        width: 216px;

        @include sp {
            width: 325/750 * 100vw;
            margin: 0 auto;
        }
        /*@include rsp{
            width: 216/1366*100vw;
        }*/

        img {
            width: 100%;
        }
    }

    &__block {
        display: flex;
        align-items: center;

        @include sp {
            display: block;
        }
    }

    &__list {
        display: flex;
        @include sp {
            margin-top: 30/750 * 100vw;
            justify-content: center;
        }
    }

    &__item {
        &:not(:last-child) {
            position: relative;

            &:after {
                position: absolute;
                content: "";
                background: #d6d6d6;
                width: 1px;
                height: 12px;

                right: 0;
                top: 50%;
                transform: translate(0, -50%);
            }
        }
        a {
            font-size: 14px;
            color: #fff;
            padding: 0 21px;

            @include sp {
                @include vw(24);
                padding: 0 28/750 * 100vw;
            }
            /*@include rsp{
                font-size: 14/1366*100vw;
                padding: 0 21/1366*100vw;
            }*/
        }
    }

    &__icon {
        display: flex;

        @include sp {
            justify-content: center;
            margin-top: 50/750 * 100vw;
        }

        li {
            &:first-child {
                padding-right: 20px;
                @include sp {
                    padding-right: 28/750 * 100vw;
                }
            }
        }
    }

    &__copy {
        text-align: right;
        margin-top: 26px;

        @include sp {
            text-align: center;
            margin-top: 28/750 * 100vw;
        }

        small {
            font-size: 12px;
            color: #fff;
            display: block;

            letter-spacing: 0.05em;

            @include sp {
                @include vw(20);
            }
            /*@include rsp{
                font-size: 12/1366*100vw;
            }*/
        }
    }

    &__toTop {
        position: fixed;
        bottom: 20px;
        right: 62/1460 * 100vw;
        z-index: 10;
        cursor: pointer;
        // transition: all .3s;

        @include sp {
            width: 76/750 * 100%;
            bottom: 30/750 * 100%;
            right: 30/750 * 100%;
        }
        /*@include rsp{
            bottom: 20/1366*100vw;
        }*/

        &:hover {
            opacity: 0.8;
            // transition: all 0.3s;
        }
    }
}
