/* --------------------------------------
 cookie
-------------------------------------- */

.box-inner{
    display: flex;
}
#box-cookie-01 {
    background: rgba(0, 91, 172, 0.9);
    bottom: -100%;
    color: #ffffff;
    left: 0;
    padding: 15px 20px 30px 20px;
    position: fixed;
    transition: 0.7s bottom;
    width: 100%;
    z-index: 9999;
}

#box-cookie-01 .btn-close::after {
    clear: both;
    content: "";
    display: block;
}

#box-cookie-01 #cookie-close-01 {
    display: block;
    float: right;
    height: 20px;
    overflow: hidden;
    position: relative;
    width: 20px;
}

#box-cookie-01 #cookie-close-01 span {
    display: block;
    position: absolute;
    right: -999em;
    top: -999em;

}

#box-cookie-01 #cookie-close-01::before {
    background: #ffffff;
    content: "";
    display: block;
    height: 2px;
    left: -1px;
    position: absolute;
    top: 9px;
    transform: rotate(45deg);
    width: 22px;
}

#box-cookie-01 #cookie-close-01::after {
    background: #ffffff;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    right: -1px;
    top: 9px;
    transform: rotate(-45deg);
    width: 22px;
}

#box-cookie-01 .box-inner {
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

#box-cookie-01 .box-inner .area-01 {
    font-size: 1.4rem;
    padding-right: 50px;
}

#box-cookie-01 .box-inner .area-01 > *:first-child {
    margin-top: 0;
}

#box-cookie-01 .box-inner .area-01 a {
    color: #ffffff;
    text-decoration: underline;
}

#box-cookie-01 .box-inner .area-01 a:hover,
#box-cookie-01 .box-inner .area-01 a:focus {
    text-decoration: none;
}

#box-cookie-01 .box-inner #cookie-btn-01 {
    background: #ffffff;
    border: #aaaaaa 1px solid;
    border-radius: 50px;
    display: block;
    min-width: 200px;
    padding: 7px 30px 6px 30px;
    transition: 0.2s all;
}

#box-cookie-01 .box-inner #cookie-btn-01 span {
    color: #333333;
    display: inline-block;
    @include fs(14);
    line-height: 2;
    padding-left: 15px;
    position: relative;
    text-align: center;
    text-decoration: none;
}

#box-cookie-01 .box-inner #cookie-btn-01 span::before {
    border-right: solid 2px #005bac;
    border-top: solid 2px #005bac;
    content: "";
    display: block;
    height: 8px;
    left: -.5em;
    margin-top: -4px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg) translate(0,-50%);
    width: 8px;
    will-change: transform;
}

@media only screen and (max-width: 750px) {
    #box-cookie-01 .box-inner {
        display: block;
    }
    #box-cookie-01 .box-inner .area-01 {
        margin-top: 10px;
        padding-right: 0;
    }
    #box-cookie-01 .box-inner #cookie-btn-01 {
        margin-top: 15px;
        min-width: auto;
        width: 100%;
    }
}
