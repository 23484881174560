@charset "utf-8";

.p-history {
    &__inner{
        padding: 200px 103px 0;
        position: relative;
        height: 920px;
        z-index: 3;
        @include rsp {
            padding: 200px 103/1366*100vw 0;
            /*height: 920/1366*100vw;*/
        }
        @include sp{
            padding: 130/750*100vw 40/750*100vw 0;
            height: auto;
        }
        @include en{
            font-family  : "Montserrat", "Noto Sans JP";
        }
    }
    &__lead{
        width: 415px;
        padding-bottom: 450px;
        line-height: 2.125em;
        @include sp{
            @include vw(28);
            padding-bottom: 1340/750*100vw;
            width: 100%;
            line-height: 1.71em;
        }
        @include en{
            line-height: 1.87;
        }
    }
    &__img{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 460px;
        @include rsp{
            left: 460/1366*100vw;
        }
        @media screen and (min-width: 1900px){
            left: 560/1366*100vw;
        }
        @include sp{
            left: auto;
            top: auto;
            bottom: -180/750*100vw;
        }
        img{
            width: 785px;
            position: relative;
            z-index: 3;
            @include sp{
                width: 670/750*100vw;
            }
        }
    }

}