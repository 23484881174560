@charset "utf-8";

.p-news{
    &__inner{
        padding: 130px 103px 100px;
        display: flex;
        position: relative;
        justify-content: space-between;
        z-index: 2;
        @include rsp {
            padding: 130px 103/1366*100vw 100px;
        }
        @include sp{
            padding: 130/750*100vw 40/750*100vw 0;
            display: block;
        }
    }
    &__list{
        width: 765px;
        @include rsp{
            width: 765/1366*100vw;
        }
        @include sp{
            width: 100%;
            padding-bottom: 120/750*100vw;
        }
    }
    &__item{
        display: flex;
        padding: 45px 0;
        border-bottom: 1px solid #DDDDDD;
        background-color: #fff;
        a{
            display: flex;
        }
        @include sp{
            padding: 88/750*100vw 0;
        }
        
        &:first-child{
            border-top: 1px solid #DDDDDD; 
        }
    }
    &__itemDay{
        margin-right: 20px;
        @include sp{
            margin-right: 20/750*100vw;
            @include vw(28);
        }
    }
    &__itemTtl{
        
        @include sp{
            @include vw(28);
        }
        @include en{
            font-family    : "Montserrat", "Noto Sans JP";
            sup{
                font-size: 10px;
                vertical-align: 4px ;
                @include sp{
                    vertical-align: 6/750*100vw ;
                    @include vw(15);
                }
            }
        }
    }
}