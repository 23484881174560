@charset "utf-8";

.p-feature {
    position: relative;
    background-color: #EDEDED;
    &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 903.5px;
        background: url(/assets/img/feature/feature_bg.jpg) no-repeat;
        background-size: cover;
        top: -100px;
        z-index: 0;
        @include rsp {
            height: 903.5/1366*100vw;
        }
        @include sp{
            background: url(/assets/img/feature/feature_bg_sp.jpg) no-repeat;
            background-size: cover;
            height: 1612/750*100vw;
            top: 0;
        }
    }

    &__inner{
        padding: 130px 103px 0;
        position: relative;
        z-index: 2;

        @include rsp {
            padding: 130px 103/1366*100vw 0;
        }
        @include sp{
            padding: 130/750*100vw 40/750*100vw 0;
        }
    }
    &__contents{
        padding-left: 103px;
        width: 100%;
        position: relative;
        z-index: 2;

        @include rsp {
            padding-left: 103/1366*100vw;
        }
        @include sp{
            padding-left: 0;
        }
        @include en{
            font-family  : "Montserrat", "Noto Sans JP";
        }
    }
    &__contentsImg{
        height: 300px;
        @include rsp{
            height: 300/1366*100vw;
        }
        @include sp{
            height: 400/750*100vw;
        }
        img{
            height: 100%;
        }
    }
    &__list{
        @include sp{
            position: relative;
            top: -100/750*100vw;
        }
    }
    &__item{
        width: 100%;
        display: flex;
        border-bottom: 1px solid #fff;
        &:last-child{
            border-bottom: none;
        }
        @include sp{
            display: block;
            border-bottom: none;
            padding-left: 40/750*100vw;
        }
    }
    &__itemImg{
        width: 500px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        @include rsp{
            width: 500/1366*100vw;
        }
        @include sp{
            width: auto;
        }
        img{
            width: 500px;
            @include rsp{
                width: 500/1366*100vw;
            }
            @include sp{
                width: 100%;
            }
        }
    }
    &__itemTxt{
        width: 752px;
        background-color: #000;
        color: #fff;
        padding: 60px 40px 0;
        position: relative;
        @include rsp{
            padding: 60px 40px 0;
            width: 752/1366*100vw;
        }
        @include sp{
            padding: 60/750*100vw 40/750*100vw 40/750*100vw;
            width: 100%;
            text-align: center;
        }
        @include en{
            padding: 60px 15px 0 40px;
            @include sp{
                padding: 60/750*100vw 40/750*100vw 40/750*100vw;
            }
        }
        &01::before{
            content: "01";
            font-size: 24px;
            font-weight: 600;
            position: absolute;
            left: 15px;
            top: 10px;
            font-family  : "Montserrat", "Noto Sans JP";
            @include sp{
                @include vw(40);
                left: 15/750*100vw;
                top: 10/750*100vw;
            }
        }
        &02{
            p{
                @include sp{
                    margin: 0 calc(50% - 50vw) 40/750*100vw;
                    padding: 0 50/750*100vw 0 ;
                    width: 100vw;
                }
            }

            &::before{
                content: "02";
                font-size: 24px;
                font-weight: 600;
                position: absolute;
                left: 15px;
                top: 10px;
                font-family  : "Montserrat", "Noto Sans JP";
                @include sp{
                    @include vw(40);
                    left: 15/750*100vw;
                    top: 10/750*100vw;
                }
            }

        }
        &03::before{
            content: "03";
            font-size: 24px;
            font-weight: 600;
            position: absolute;
            left: 15px;
            top: 10px;
            font-family  : "Montserrat", "Noto Sans JP";
            @include sp{
                @include vw(40);
                left: 15/750*100vw;
                top: 10/750*100vw;
            }
        }
        &04::before{
            content: "04";
            font-size: 24px;
            font-weight: 600;
            position: absolute;
            left: 15px;
            top: 10px;
            font-family  : "Montserrat", "Noto Sans JP";
            @include sp{
                @include vw(40);
                left: 15/750*100vw;
                top: 10/750*100vw;
            }
        }
    }
    &__itemTtl{
        font-size: 34px;
        font-weight: 600;
        margin-bottom: 20px;
        @include sp{
            @include vw(50);
            margin-bottom: 40/750*100vw;
            line-height: 1.4em;
        }
    }
    &__itemLead{
        margin-bottom: 75px;
        line-height: 2.125em;
        @include sp{
            @include vw(28);
            margin-bottom: 50/750*100vw;
            line-height: 1.71em;
        }
        @include en{
            margin-bottom: 40px;
            line-height: 1.87;
            @include sp{
                @include vw(28);
                margin-bottom: 50/750*100vw;
                line-height: 1.71em;
            }
        }
        span{
            font-size: 10px;
            @include sp{
                @include vw(15);
            }
        }
    }
    &__achvTtl{
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        @include sp{
            @include vw(34);
            margin-bottom: 20/750*100vw;
        }
    }
    &__achvList{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
        @include sp{
            display: block;
        }
    }
    &__achvItem{
        border: 1px solid #fff;
        padding: 10px 10px 10px 35px;
        width: 300px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;
        @include sp{
            @include vw(28);
            margin-bottom: 20/750*100vw;
            width: 100%;
            padding: 32/750*100vw 0 32/750*100vw 80/750*100vw;
            text-align: left;
        }
        @include en{
            width: 330px;
            margin-right: 15px;
            font-size: 14px;
            line-height: 1.8;
            padding: 13px 10px 13px 35px;
            @include sp{
                width: 100%;
                @include vw(28);
                padding: 33/750*100vw 0 33/750*100vw 55/750*100vw;
                line-height: 1.5;
                //height: 110/750*100vw;
                
    
            }

            
        }
        &::before{
            content: "";
            background: url(/assets/img/common/m-ttl.svg) no-repeat;
            background-position: center;
            background-size: cover;
            position: absolute;
            top: 35%;
            width: 13px;
            height: 15px;
            left: 5%;
            @include sp{
                top: 38%;
                width : 22.76/750*100vw;
                height: 26.28/750*100vw;
            }
            @include en{
                top: 17px;
                @include sp{
                    top: 40/750*100vw;
                    left: 3%;
                }
            }
        }
    }
    &__contentsTxt{
        font-size: 34px;
        font-weight: bold;
        line-height: 1.88em;
        padding-top: 100px;
        text-align: center;
        padding-bottom: 100px;
        @include sp{
            @include vw(36);
            line-height: 1.66em;
            padding-top: 0;
            padding-bottom: 100/750*100vw;
        }
    }
}