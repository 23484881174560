.m-txt{
    &__sec{
        @include fs(15);
        color: #656D73;
        letter-spacing: .05em;
        line-height: 2;
        margin-top: 40px;
        @include sp {
            @include vw(30);
        }
        @include en{
            letter-spacing: 0;
            line-height: 1.6;
        }
    }
    &__block{
        @include fs(15);
        color: #656D73;
        letter-spacing: .05em;
        line-height: 2;

        margin-top: 24px;

        @include sp{
            @include vw(30);
            margin-top: 32/750*100vw;
        }

        @include en{
            letter-spacing: 0;
            line-height: 1.6;
        }

        &--black{
            color: #000;
            font-weight: 500;
            @include sp {
                @include vw(30);
            }
        }
    }
}