@charset "utf-8";
.l-main{
    position: relative;
    min-width: 1300px;
    overflow: hidden;
    

    @include sp{
        min-width: 100%;
    }

    &__inner{
        max-width: 1044px + 40px;

        margin: 0 auto;

        padding: 0 20px;

        @include sp{
           padding: 0 40/750*100vw;
        }
    }
}
.scrolldown{
    /*描画位置※位置は適宜調整してください*/
  position:absolute;
  bottom:1%;
  left:7%;
  width: 10.5px;
  height: 298px;
    /*矢印の動き1秒かけて永遠にループ*/
  animation: arrowmove 2.5s ease-in-out infinite;
  z-index: 99;
  @include sp{
    width: 25/750*100vw;
    height: 181/750*100vw;
    bottom: 5%;
  }
}

/*下からの距離が変化して全体が下→上→下に動く*/
@keyframes arrowmove{
  0%{bottom:1%;}
  50%{bottom:3%;}
 100%{bottom:1%;}
}
@include sp{
  @keyframes arrowmove{
    0%{bottom:5%;}
    50%{bottom:7%;}
   100%{bottom:5%;}
}

}


/*Scrollテキストの描写*/
.scrolldown span{
    /*テキストの形状*/
  color: #eee;
  font-size: 14px;
  letter-spacing: 0.05em;
  /*縦書き設定*/
  -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;

    @include sp{
        @include vw(20);
    }
}

.scrolldown:after{
  content:"";
    /*描画位置*/
  position: absolute;
  bottom:0;
  right:0;
    /*矢印の形状*/
  width:1px;
  height: 238px;
  background:#eee;
  @include sp{
    height: 104/750*100vw;
    width: 2px;
    right: 8/750*100vw;
}
}