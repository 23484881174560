@charset "utf-8";

.swiper-container{
    z-index: 2;
}
.swiper-wrapper{
    width: 100%;
    padding-bottom: 80px;
    @include rsp {
        padding-bottom: 80/1336*100vw;
    }
    @include sp{
        padding-bottom: 150/750*100vw;
    }
}
.swiper-slide{
    width: 100%;
    height: 690px;
    background-color: #fff;
    position: relative;
    @include sp{
        height: 1060/750*100vw;
    }
    @include en{
        @include sp{
            height: 1303/750*100vw;
        }
    }
    &::after{
        content: "";
        width: 13px;
        height: 10px;
        position: absolute;
        background: url(/assets/img/project/item_icon.svg) no-repeat;
        background-position: center;
        background-size: cover;
        right: 10px;
        bottom: 10px;
        @include sp{
            width: 33/750*100vw;
            height: 25/750*100vw;
            right: 20/750*100vw;
            bottom: 20/750*100vw;
        }
    }
}
.swiper-icon{
    &::after{
        display: none;
    }

}
.swiper-button-next{
    left: 715px;
    top: auto;
    bottom: 0px;
    background: url(/assets/img/project/btn_next.png) no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
    z-index: 11;
    @include rsp{
        left: 715/1336*100vw;
        width: 50/1336*100vw;
        height: 50/1336*100vw;
    }
    @include sp{
        left: 630/750*100vw;
        width: 86/750*100vw;
        height: 86/750*100vw;
    }
}
.swiper-button-prev{
    left: 370px;
    top: auto;
    bottom: 0px;
    background: url(/assets/img/project/btn_prev.png) no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
    z-index: 11;
    @include rsp{
        left: 370/1336*100vw;
        width: 50/1336*100vw;
        height: 50/1336*100vw;
    }
    @include sp{
        left: 40/750*100vw;
        width: 86/750*100vw;
        height: 86/750*100vw;
    }
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
    margin: 0 17px;
    @include sp{
        margin: 0 20/750*100vw;
    }
}

.swiper-pagination-bullet{
    &:nth-child(1){
        background: url(/assets/img/project/01.svg) no-repeat;
        background-size: contain;
        width: 28px;
        height: 24px;
        border-radius: 0;
        @include rsp{
            width: 28/1336*100vw;
            height: 24/1336*100vw;
        }
        @include sp{
            width: 57/750*100vw;
            height: 42/750*100vw;
        }

    }
    &:nth-child(2){
        background: url(/assets/img/project/02.svg) no-repeat;
        background-size: contain;
        width: 32px;
        height: 24px;
        border-radius: 0;
        @include rsp{
            width: 32/1336*100vw;
            height: 24/1336*100vw;
        }
        @include sp{
            width: 60/750*100vw;
            height: 42/750*100vw;
        }

    }
    &:nth-child(3){
        background: url(/assets/img/project/03.svg) no-repeat;
        background-size: contain;
        width: 28px;
        height: 24px;
        border-radius: 0;
        @include rsp{
            width: 28/1336*100vw;
            height: 24/1336*100vw;
        }
        @include sp{
            width: 48/750*100vw;
            height: 42/750*100vw;
        }
    }  
    &:nth-child(4){
        background: url(/assets/img/project/04.svg) no-repeat;
        background-size: contain;
        width: 28px;
        height: 24px;
        @include rsp{
            width: 28/1336*100vw;
            height: 24/1336*100vw;
        }
        @include sp{
            width: 48/750*100vw;
            height: 42/750*100vw;
        }
    }  
}
.p-project {
    background: #ededed;
    padding: 0 103px 80px;
    @include rsp {
        padding: 0 103/1366*100vw 80px;
    }
    @include sp{
        padding: 0 0 100/750*100vw;
    }
    
    &__inner{
        padding: 130px 0 0;
        position: relative;
        z-index: 3;
        @include sp{
            padding: 130/750*100vw 40/750*100vw 0;
        }
        @include en{
            font-family  : "Montserrat", "Noto Sans JP";
        }
    }
    &__lead{
        margin-bottom: 40px;
        @include sp{
            margin-bottom: 40/750*100vw;
            @include vw(28);
        }
    }
    &__logo{
        height: 260px;
        display: flex;
        align-items: center;
        justify-content: center;
        border:  1px solid #EEEEEE;
        margin-bottom: 25px;
        @include sp{
            height: 470/750*100vw;
            margin-bottom: 25/750*100vw;
        }
        &--green{
            background-color: #108A25;
        }
        img{
            width: 100%;
        }
    }
    &__item{
        height: 405px;
        @include sp{
            height: 565/750*100vw;
        }
        @include en{
            @include sp{
                height: 810/750*100vw;
            }
        }
    }
    &__itemTtl{
        padding: 0 23px;
        color: #1F87D3;
        font-size: 20px;
        font-weight: 600;
        font-family  : "Montserrat", "Noto Sans JP";
        margin-bottom: 10px;
        @include sp{
            padding: 0 40/750*100vw;
            margin-bottom: 10/750*100vw;
            @include vw(35);
        }
        
    }
    &__itemTxt{
        padding: 0 23px;
        line-height: 2.125em;
        margin-bottom: 5px;
        @include sp{
            padding: 0 40/750*100vw;
            margin-bottom: 10/750*100vw;
            @include vw(28);
            line-height: 1.71em;
        }
        @include en{
            font-family  : "Montserrat", "Noto Sans JP";
            line-height: 1.71em;
            font-size: 14px;
            padding: 0 23px;
            @include sp{
                @include vw(28);
            }
            .co2{
                font-size: 10px;
                @include sp{
                    @include vw(19);
                    position: relative;
                    top: 2/750*100vw;
                }
            }
        }

    }
    &__itemAlert{
        padding: 0 17px;
        font-size: 13px;
        @include sp{
            padding: 0 30/750*100vw;
            @include vw(23);
        }
        @include en{
            font-size: 12px;
            @include sp{
                @include vw(22);
            }
        }
    }

}