@charset "utf-8";

.p-hydrogen {
    position: relative;
    z-index: 2;
    &__inner{
        padding: 200px 103px 0;
        @include sp{
            padding: 100/750*100vw 40/750*100vw 0;
        }

    }
    &__contents{
        background-color: #000;
        color: #fff;
        width: 1262px;
        display: flex;
        padding: 40px 55px 60px;
        @include rsp{
            width: 1262/1366*100vw;
            padding: 40/1366*100vw 55/1366*100vw 60/1366*100vw;
        }
        @include sp{
            width: 100%;
            display: block;
            padding: 0 40/750*100vw 100/750*100vw;
        }
        @include en{
            font-family  : "Montserrat", "Noto Sans JP";
            padding: 40px 55px 40px;
            @include rsp{
                width: 1262/1366*100vw;
                padding: 40/1366*100vw 55/1366*100vw 40/1366*100vw;
            }
            @include sp{
                padding: 0 40/750*100vw 100/750*100vw;
            }
        }
    }
    &__feature,&__kind{
        width: 50%;
        text-align: center;
        @include sp{
            width: 100%;
            text-align: left;
        }
        &Ttl{
            font-size: 34px;
            font-weight: 500;
            margin-bottom: 10px;
            padding-top: 45px;
            @include sp{
                margin-bottom: 30/750*100vw;
                padding-top: 70/750*100vw;
                @include vw(40);
            }
        }
        &Lead{
            line-height: 2.125em;
            @include sp{
                @include vw(28);
                line-height: 1.71em;
            }
            @include en{
                line-height: 1.87;

            }
        }
    }
    &__feature{
        border-right: 1px solid #fff;
        padding-right: 55px;
        @include sp{
            border-right: none;
            border-bottom: 1px solid #fff;
            padding-right: 0;
            padding-bottom: 60/750*100vw;
        }
        &Lead{
            margin-bottom: 90px;
            @include sp{
                margin-bottom: 40/750*100vw;
            }
            @include en{
                margin-bottom: 40px;
            }

        }
        &ListTtl{
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
            @include sp{
                @include vw(34);
                margin-bottom: 20/750*100vw;
            }
        }
    }
    &__kind{
        padding-left: 55px;
        @include sp{
            padding-left: 0;
        }
        &Lead{
            margin-bottom: 30px;
            @include sp{
                margin-bottom: 90/750*100vw;
            }
            @include en{
                margin-bottom: 60px;
                .co2{
                    font-size: 11px;
                    position: relative;
                    top: -3px;
                    @include sp{
                        @include vw(21);
                            top: -4/750*100vw;
                    }
                }
            }

        }
        &Img{
            img{
                width: 508px;
                margin: 0 auto;
                @include sp{
                    width: 100%;
                }
            }

        }
    }
    &__featureList{
        display: flex;
        justify-content: space-between;
        width: 519.75px;
        margin: 0 auto;
        @include sp{
            display: block;
            width: 100%;
        }
        @include en{
            display: block;
            width: 100%;
            margin-bottom: 40px;
        }
        
    }
    &__featureItem{
        border: 1px solid #fff;
        padding: 20px 10px 5px;
        width: 160px;
        @include sp{
            width: 100%;
            padding: 25/750*100vw 45/750*100vw;
            display: flex;
            margin-bottom: 30/750*100vw;
            height: 140/750*100vw;
        }
        @include en{
            display: flex;
            align-items: center;
            width: 420px;
            margin: 0 auto;
            height: 70px;
            padding: 0 10px 0 20px;
            text-align: left;
            margin-bottom: 20px;
            letter-spacing: 0.04em;
            @include sp{
                width: 100%;
                height: 140/750*100vw;
                padding: 0 10/750*100vw 0 30/750*100vw;
                margin-bottom: 30/750*100vw;
            }
        }
        h5{
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
            font-family  : "Montserrat", "Noto Sans JP";
            @include sp{
                @include vw(40);
                margin-bottom: 0;
                margin-right: 40/750*100vw;
                display: flex;
                align-items: center;
            }
            @include en{
                display: flex;
                align-items: center;
                margin-bottom: 0;
                @include sp{
                    margin-right: 30/750*100vw;
                }
            }
        }
        &Txt{
            font-size: 15px;
            line-height: 2.26em;
            margin-bottom: 15px;
            @include sp{
                @include vw(28);
                display: flex;
                align-items: center;
                line-height: 1.8em;
                margin-bottom: 0;
            }
            @include en{
                margin-bottom: 0;
                line-height: 1.4em;
                padding-left: 20px;
                font-weight: 500;
                @include sp{
                    padding-left: 0;
                    display: inline;
                }
                .co2{
                    font-size: 11px;
                    position: relative;
                    top: 1px;
                    @include sp{
                        @include vw(21);
                        top: 1/750*100vw;
                    }
                }
            }

        }
        &Txt2{
            font-size: 13px;
            line-height: 1.69em;
            margin-bottom: 5px;
            @include sp{
                @include vw(20);
                line-height: 1.6em;
                margin-bottom: 0;
            }
            @include en{
                font-size: 12px;
                margin-bottom: 0;
                line-height: 1.4;
                padding-left: 20px;
                @include sp{
                    padding-left: 0;
                    @include vw(20);
                }
            }
        }
    }
    

}