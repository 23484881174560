@charset "utf-8";

.p-kv {
    position           : relative;
    background         : #EDEDED ;
    height             : 717px;
    min-height         : 717px;
    margin             : 90px auto 0;
    @include rsp{
        /*margin-top: 90/1366 * 100vw;*/
        height             : 717/1366*100vw;
        min-height         : 717/1366*100vw;
    }
    @include sp{
        min-height     : 0;
        margin-top: 120/750*100vw;
        height         : 900/750*100vw;
    }

    &__wrap{
        height: 100%;
        background: url(/assets/img/kv/kv_img.png) no-repeat top center;
        background-size    : cover;
        background-position: top;
        position: relative;
        z-index: 2;
        @include sp {
            background     : url(/assets/img/kv/kv_img--sp.png) no-repeat top center;
            background-size: cover;
            
        }
    }

    &__inner {
        max-width: 1044 + 40px;
        margin   : 0 auto;
        padding  : 0 20px;


        @include rsp {
            max-width: 1084/1366*100vw;
        }

        @include sp {
            padding: 0 95/750*100vw;


        }
    }

    &__ttl {
        font-size  : 36px;
        font-weight: bold;
        color      : #fff;
        line-height: 1.46;
        padding-top: 200px;

        @include rsp {
            padding-top: 200/1366*100vw;
            font-size  : 36/1366*100vw;
        }

        @include sp {
            @include vw(33);
            padding-top  : 560/750*100vw;
            margin-bottom: 20/750*100vw
        }

        @include en {
            @include fs(36);
            font-family  : "Montserrat", "Noto Sans JP";
            font-weight: 600;
            line-height   : 1.2;
            margin-bottom: 10px;

            @include rsp {
                padding-top: 200/1366*100vw;
                font-size  : 36/1366*100vw;
                margin-bottom: 10/1366*100vw;
            }

            @include sp {
                @include vw(33);
                margin-bottom: 10/750*100vw
            }
        }
    }

    &__img {
        img {
            @include rsp {
                height: 109.6/1366*100vw;
            }
            @include sp {
                height: 103.6/750*100vw;
            }
        }
    }

}